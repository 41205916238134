import Home from './Containers/Home';
import ActionPlanList from './Containers/ActionPlanList';
import ActionPlanDashboard from './Containers/ActionPlanDashboard';
import ActionPlanBulkEdit from './Containers/ActionPlanBulkEdit';

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routes = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/action-plan-list',
    exact: true,
    component: ActionPlanList,
  },
  {
    path: '/action-plan-dashboard',
    exact: true,
    component: ActionPlanDashboard,
  },
  {
    path: '/action-plan-bulk-edit',
    exact: true,
    component: ActionPlanBulkEdit,
  },
  {
    path: '/id-homepage',
    exact: true,
    component: IDHomepage,
  },
];
